import { MemberStateInterface } from "./state";
import { MemberBankInfoResponse } from "@/types/Member/Response/MemberBankInfoResponse";
import { MemberResponse } from "@/types/Member/Response/MemberResponse";
import { AspUserClientResponse } from "@/types/Member/Response/AspUserClientResponse";
import { MutationTree } from "vuex";
import { MemberReferralResponse } from "@/types/Member/Response/MemberReferralResponse";
import { MemberGroupResponse } from "@/types/Member/Response/MemberGroupResponse";
import { MemberCurrentTierInfoResponse } from "@/types/Member/Response/MemberCurrentTierInfoResponse";

export enum MemberMutations {
	SET_MEMBER_BANK = "SET_MEMBER_BANK",
	SET_MEMBER = "SET_MEMBER",
	SET_MEMBER_COUNT = "SET_MEMBER_COUNT",
	SET_USER_CLIENT = "SET_USER_CLIENT",
	SET_ON_LOAD_MEMBERBANK = "SET_ON_LOAD_MEMBERBANK",
	SET_IS_MEMBERBANK_EXIST = "SET_IS_MEMBERBANK_EXIST",
	SET_MEMBER_REFERRAL = "SET_MEMBER_REFERRAL",
  SET_MEMBER_GROUP = "SET_MEMBER_GROUP",
  SET_MEMBER_CURRENT_TIER_INFO = "SET_MEMBER_CURRENT_TIER_INFO",
}

export const mutations: MutationTree<MemberStateInterface> = {
	[MemberMutations.SET_MEMBER_BANK](state, payload: MemberBankInfoResponse[]) {
		const memberBank = payload.filter((memberBank) => memberBank.primary == true);

		state.memberBank = memberBank[0];
	},
	[MemberMutations.SET_MEMBER](state, payload: MemberResponse) {
		state.member = payload;
	},
	[MemberMutations.SET_MEMBER_COUNT](state, payload: number) {
		state.memberCount = payload;
	},
	[MemberMutations.SET_USER_CLIENT](state, payload: AspUserClientResponse) {
		state.aspUserClient = payload;
	},
	[MemberMutations.SET_ON_LOAD_MEMBERBANK](state, payload) {
		state.onLoadMemberBank = payload.onLoadMemberBankStatus;
	},
	[MemberMutations.SET_IS_MEMBERBANK_EXIST](state, payload) {
		state.isMemberBankExist = payload.isMemberBankExist;
	},
	[MemberMutations.SET_MEMBER_REFERRAL](state, payload: MemberReferralResponse) {
		state.memberReferral = payload;
	},
  [MemberMutations.SET_MEMBER_GROUP](state, payload: MemberGroupResponse) {
    state.memberGroup = payload;
  },
  [MemberMutations.SET_MEMBER_CURRENT_TIER_INFO](state, payload: MemberCurrentTierInfoResponse) {
    state.memberCurrentTierInfo = payload;
  },
};
