<template>
  <b-row align-h="center" class="my-3 gameAccountContainer">
    <b-row class="my-2 text-center">
      <label class="text-start">{{ $t("DownloadAccount.Username") }}</label>
      <b-input-group>
        <b-form-input
          id="download-username"
          v-model="downloadAccountForm.vendorGameLoginId"
          type="text"
          v-bind:placeholder="$t('DownloadAccount.Username')"
          disabled
        ></b-form-input>
        <b-input-group-append class="mx-3">
          <b-button variant="copyText" @click="copyToClipboard('download-username')">
            {{ $t("DownloadAccount.Copy") }}
            <font-awesome-icon :icon="['fas', 'copy']" class="ms-2" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-row>

    <b-row class="my-2 text-center">
      <label class="text-start">{{ $t("DownloadAccount.Password") }}</label>
      <b-input-group>
        <b-form-input
          id="download-password"
          size="md"
          v-model="downloadAccountForm.newPassword"
          type="text"
          v-bind:placeholder="$t('DownloadAccount.Password')"
          required
        ></b-form-input>
        <b-input-group-append class="mx-3">
          <b-button @click="copyToClipboard('download-password')" variant="copyText">
            {{ $t("DownloadAccount.Copy") }}
            <font-awesome-icon :icon="['fas', 'copy']" class="ms-2" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="input-errors" v-for="(error, index) of v$.downloadAccountForm.newPassword.$errors" :key="index">
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </b-row>
    <b-row class="my-2">
      <b-col cols="6">
        <b-button variant="main" id="downloadGame" @click="UpdatePassword">
          {{ $t("DownloadAccount.Update") }}
        </b-button>
      </b-col>
    </b-row>
  </b-row>
</template>

<style scoped>
.btn-copyText {
  height: 39px;
  width: 125px;
  background: linear-gradient(to bottom, rgba(97, 242, 255, 1) 0%, rgba(50, 159, 202, 1) 100%) !important;
  border-color: 1px solid #cdcdcd !important;
  border-radius: 2px;
  color: #262626;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.btn-copyText:hover,
.btn-copyText:active {
  background: #298fc2 !important;
}
.error-msg {
  text-align: left;
}
#downloadGame {
  height: 39px !important;
  width: 100% !important;
}

@media (min-width: 992px) {
  .gameAccountContainer {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (max-width: 767px) {
  .btn-downloadGame {
    width: 80% !important;
  }
}
</style>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      downloadAccountForm: {
        newPassword: {
          required: helpers.withMessage(() => this.$t("DownloadAccount.newPasswordRequired"), required),
        },
      },
    };
  },
  data() {
    return {
      promoUrl: "spribe/assets/images/Banner/promotion-banner.png",
      downloadAccountForm: {
        phone: "",
        vendorGameLoginId: "",
        vendorGamePassword: "",
        newPassword: "",
      },
      onUpdating: false,
      show: true,
    };
  },
  computed: {
    getVendorGameID() {
      return this.$store.getters["memberAccount/getMemberAccount"].vendorGameId;
    },
    getVendorGamePassword() {
      return this.$store.getters["memberAccount/getMemberAccount"].vendorPassword;
    },
    getVendorGameNewPassword() {
      return this.$store.getters["memberAccount/getMemberAccount"].vendorPassword;
    },
  },
  methods: {
    successCopy() {
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      this.toast.success(this.$t("ReferralPage.CopySuccess"), { position: toastPosition });
    },
    errorCopy() {
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      this.toast.error(this.$t("ReferralPage.CopyError"), { position: toastPosition });
    },
    async UpdatePassword() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      await this.$store
        .dispatch("memberAccount/updateGamePassword", this.downloadAccountForm)
        .then(() => {
          loader.hide();
          this.fetchMemberAccount();
          // this.getMemberAccount();
          this.toast.success(this.$t("DownloadAccount.ChangePasswordSuccess"), { position: toastPosition });
        })
        .catch(() => {
          loader.hide();
          // this.fetchMemberAccount();
          this.getMemberAccount();
          this.toast.error(this.$t("DownloadAccount.ChangePasswordError"), { position: toastPosition });
        });
    },
    getMemberAccount() {
      this.downloadAccountForm.vendorGameLoginId =
        this.$store.getters["memberAccount/getMemberProductUsername"].username;
      this.downloadAccountForm.vendorGamePassword =
        this.$store.getters["memberAccount/getMemberAccount"].vendorPassword;
      this.downloadAccountForm.newPassword = this.$store.getters["memberAccount/getMemberAccount"].vendorPassword;
    },
    copyToClipboard(copyTextID) {
      var textBox = document.getElementById(copyTextID);
      if (!navigator.clipboard) {
        textBox.select();
        document.execCommand("copy");
        this.successCopy();
      } else {
        let copyText = textBox.value;
        navigator.clipboard
          .writeText(copyText)
          .then(() => {
            this.successCopy();
          })
          .catch(() => {
            this.errorCopy();
          });
      }
    },
    async fetchMemberAccount() {
      await this.$store.dispatch("vendors/fetchVendor");
      await this.$store.dispatch("vendors/fetchVendorAccount");
      await this.$store.dispatch("memberAccount/fetchMemberAccount").then(() => {
        this.getMemberAccount();
      });
      await this.$store.dispatch("member/fetchMember").then(() => {
        const memberDetail = this.$store.getters["member/getMember"];
        this.downloadAccountForm.phone = memberDetail.phoneNumber;
      });
    },
  },
  created() {
    this.fetchMemberAccount();
  },
  watch: {
    getVendorGameNewPassword: function (newValue) {
      if (newValue) {
        this.fetchMemberAccount();
      }
    },
  },
};
</script>
