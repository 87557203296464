export default {
	Home: "Laman Utama",
	Games: "Permainan",
	Download: "Muat Turun",
	Promotion: "Promos",
	Rank: "Kedudukan",
	"Contact Us": "Hubungi Kami",
	Language: "Bahasa",
	Deposit: "Deposit",
	Withdrawal: "Pengeluaran",
	"Change Password": "Tukar Kata Laluan",
	Logout: "Log Keluar",
	LogoutSuccess: "Berjaya Log Keluar",
	Profile: "Profil",
	"Bank Info": "Butiran Bank",
	Statement: "Penyata",
	Referral: "Rujukan",
	Interchange: "Tukar Saya",
	GameIDInfo: "Maklumat Akaun Game",
	SLOT: "Slot",
	LIVECASINO: "Live Casino",
  VIP: "VIP",
};
