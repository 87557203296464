export default {
	ForgotPassword: "Forgot Password",
	PhoneNumber: "Phone Number",
	Submit: "Submit",
	PhoneRequired: "*Phone Number is required",
	PhoneNumeric: "*Phone Number must be numeric",
	PhoneMin: "*Phone Number at least {minLength} digit",
	PhoneMax: "*Phone Number not more than {maxLength} digit",
	SubmitSuccess: "Submit Success",
	SubmitFailed: "Submit Failed",
};
