import { ClientStateInterface } from "./state";
import { MutationTree } from "vuex";
import { GetClientInfoResponse } from "@/types/Client/Response/GetClientInfoResponse";

export enum ClientMutations {
	SET_CLIENT_INFO = "SET_CLIENT_INFO",
}

export const mutations: MutationTree<ClientStateInterface> = {
	[ClientMutations.SET_CLIENT_INFO](state, payload: GetClientInfoResponse[]) {
		state.clientInfo = payload;
	},
};
