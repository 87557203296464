import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c4a360"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headerContainer-inner" }
const _hoisted_2 = { class: "logoContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_header_nav_bar = _resolveComponent("header-nav-bar")!
  const _component_HeaderLogin = _resolveComponent("HeaderLogin")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_MobileSubHeader = _resolveComponent("MobileSubHeader")!
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_SignUpModal = _resolveComponent("SignUpModal")!
  const _component_ForgotPasswordModal = _resolveComponent("ForgotPasswordModal")!
  const _component_ChangePasswordModal = _resolveComponent("ChangePasswordModal")!
  const _component_ProfileModal = _resolveComponent("ProfileModal")!
  const _component_BankInfoModal = _resolveComponent("BankInfoModal")!
  const _component_DepositModal = _resolveComponent("DepositModal")!
  const _component_WithdrawalModal = _resolveComponent("WithdrawalModal")!
  const _component_InterchangeModal = _resolveComponent("InterchangeModal")!
  const _component_GameIDInfoModal = _resolveComponent("GameIDInfoModal")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["sticky-top", { withSubHeader: _ctx.isLogin, isNotTop: _ctx.scrollPosition > 1 }])
  }, [
    _createVNode(_component_b_container, {
      fluid: "",
      class: "headerContainer px-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_container, {
          fluid: "",
          class: "px-0 h-100 pt-2 d-flex align-items-center flex-column"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_container, {
              class: _normalizeClass(["d-flex justify-content-between align-items-center h-100", { 'mb-2': _ctx.isLogin }])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_SideBar),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_BaseImage, {
                      imgSrc: _ctx.logoWhiteUrl,
                      mode: "logo",
                      isFullUrl: false,
                      "fluid-grow": "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/'))),
                      class: "logo-light"
                    }, null, 8, ["imgSrc"]),
                    _createVNode(_component_BaseImage, {
                      imgSrc: _ctx.logoUrl,
                      mode: "logo",
                      isFullUrl: false,
                      "fluid-grow": "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/'))),
                      class: "logo-dark"
                    }, null, 8, ["imgSrc"])
                  ])
                ]),
                _createVNode(_component_header_nav_bar),
                _createElementVNode("div", null, [
                  _createVNode(_component_HeaderLogin)
                ])
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_MobileSubHeader, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_LoginModal),
    _createVNode(_component_SignUpModal),
    _createVNode(_component_ForgotPasswordModal),
    _createVNode(_component_ChangePasswordModal),
    _createVNode(_component_ProfileModal),
    _createVNode(_component_BankInfoModal),
    _createVNode(_component_DepositModal),
    _createVNode(_component_WithdrawalModal),
    _createVNode(_component_InterchangeModal),
    _createVNode(_component_GameIDInfoModal)
  ], 2))
}