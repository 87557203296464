<template>
	<div class="w-100">
		<div class="runningTextBg">
			<b-container class="runningTextContainer">
				<div class="announcementIcon">
					<base-image :imgSrc="announcemeneIcon"></base-image>
				</div>

				<div class="runningTextContent" v-b-modal="'runningtext-modal'">
					<Vue3Marquee class="marquee text-white" :duration="30" :pause-on-hover="true" :pause-on-click="true">
						<span
							v-for="(runningText, index) in getRunningText"
							:key="`runningText-${index}`"
							class="runningTextItem"
							>{{ runningText }}</span
						>
					</Vue3Marquee>
				</div>
			</b-container>
		</div>
	</div>

	<RunningTextModal :runningTextList="runningTextList"></RunningTextModal>
</template>

<script>
import RunningTextModal from "@/components/modal/RunningTextModal.vue";
export default {
	components: {
		RunningTextModal,
	},
	data() {
		return {
			runningTextList: [],
			announcemeneIcon: "spribe/assets/images/Home/announcement_icon.png",
		};
	},
	watch: {
		"$i18n.locale": function (newLanguage) {
			this.runningTextList = this.$store.getters["announcement/getRunningText"](newLanguage);
		},
	},
	computed: {
		getRunningText() {
			return this.runningTextList;
		},
	},
	async created() {
		await this.$store.dispatch("announcement/fetchRunningText").then(() => {
			this.runningTextList = this.$store.getters["announcement/getRunningText"]();
		});
	},
};
</script>

<style scoped>
.runningTextBg {
	display: flex;
	cursor: pointer;
	align-items: center;
	background: #3c3b6e;
}
.runningTextContainer {
	display: flex;
	align-items: center;
}
.announcementIcon > img {
	height: 100% !important;
	width: auto !important;
}
.runningTextContent {
	/*max-width: 85%;*/
	color: #ffffff;
	align-items: center;
	display: block;
	font-size: 0.8rem;
	width: 100%;
}
.runningTextItem {
	margin: 0px 3rem;
	color: #ffffff;
}

@media (max-width: 767px) {
	.runningTextBg {
		height: 100%;
		font-size: 0.8rem;
		padding: 0.275rem 0px;
	}
}
</style>
