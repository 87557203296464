import { GetClientInfoResponse } from "@/types/Client/Response/GetClientInfoResponse";

export interface ClientStateInterface {
	clientInfo: GetClientInfoResponse[];
}

function state(): ClientStateInterface {
	return {
		clientInfo: [],
	};
}

export default state;
