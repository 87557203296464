export default {
	ForgotPassword: "Lupa Kata Laluan",
	PhoneNumber: "Nombor Telefon",
	Submit: "Hantar",
	PhoneRequired: "*Nombor Telefon diperlukan",
	PhoneNumeric: "*Nombor Telefon mesti berangka",
	PhoneMin: "*Nombor Telefon mesti sekurangnya {minLength} bilangan",
	PhoneMax: "*Nombor Telefon mesti tidak melebihi {maxLength} bilangan",
	SubmitSuccess: "Berjaya Hantar",
	SubmitFailed: "Hantar Gagal",
};
