<template>
  <header class="sticky-top" :class="{ withSubHeader: isLogin, isNotTop: scrollPosition > 1 }">
    <b-container fluid class="headerContainer px-0">
      <b-container fluid class="px-0 h-100 pt-2 d-flex align-items-center flex-column">
        <b-container class="d-flex justify-content-between align-items-center h-100" :class="{ 'mb-2': isLogin }">
          <div class="headerContainer-inner">
            <SideBar></SideBar>
            <!-- <span class="nav-menu-icon d-inline-flex d-md-none" onclick="openNav()">
							<i class="fa fa-bars fa-2x"></i>
						</span> -->
            <div class="logoContainer">
              <BaseImage
                :imgSrc="logoWhiteUrl"
                mode="logo"
                :isFullUrl="false"
                fluid-grow
                @click="$router.push('/')"
                class="logo-light"
              ></BaseImage>
              <BaseImage
                :imgSrc="logoUrl"
                mode="logo"
                :isFullUrl="false"
                fluid-grow
                @click="$router.push('/')"
                class="logo-dark"
              ></BaseImage>
            </div>
          </div>

          <header-nav-bar></header-nav-bar>

          <div>
            <HeaderLogin></HeaderLogin>
          </div>
        </b-container>
        <MobileSubHeader v-if="isMobile"></MobileSubHeader>
      </b-container>
    </b-container>
    <LoginModal></LoginModal>
    <SignUpModal></SignUpModal>
    <ForgotPasswordModal></ForgotPasswordModal>
    <ChangePasswordModal></ChangePasswordModal>
    <ProfileModal></ProfileModal>
    <BankInfoModal></BankInfoModal>
    <DepositModal></DepositModal>
    <WithdrawalModal></WithdrawalModal>
    <InterchangeModal></InterchangeModal>
    <GameIDInfoModal></GameIDInfoModal>
  </header>
</template>

<style scoped>
.headerContainer {
  height: 100%;
  box-shadow: 0px 2px 3px 0px #0000004d;
}
.headerContainer-inner {
  display: flex;
  align-items: center;
}
.nav-menu-icon {
  vertical-align: middle;
  margin-right: 15px;
}
</style>
<style>
.isNotTop {
  background: #252525;
  transition: 0.5s all;
}
.logo-light {
  display: none;
}

.isNotTop .logo-light {
  display: block;
}

.logo-dark {
  display: block;
}

.isNotTop .logo-dark {
  display: none;
}
header {
  height: 123px;
}

.logoContainer {
  width: 120px;
}

.header-logo-center {
  display: none;
}

.logo {
  max-width: 150px !important;
  cursor: pointer;
}

@media (max-width: 767px) {
  header {
    height: 70px;
  }

  header.withSubHeader {
    height: 100px;
  }

  .header-logo-center {
    display: none;
  }
  .logoContainer {
    width: 90px;
  }
  .logo {
    max-width: 90px !important;
  }
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderNavBar from "@/components/content/Header/HeaderNavBar.vue";
import HeaderLogin from "@/components/content/Header/HeaderLogin.vue";
import MobileSubHeader from "@/components/content/Header/MobileSubHeader.vue";
import SideBar from "@/components/content/Header/SideBar.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import LoginModal from "@/components/modal/LoginModal.vue";
import SignUpModal from "@/components/modal/SignUpModal.vue";
import ForgotPasswordModal from "@/components/modal/ForgotPasswordModal.vue";
import ChangePasswordModal from "@/components/modal/ChangePasswordModal.vue";
import ProfileModal from "@/components/modal/ProfileModal.vue";
import BankInfoModal from "@/components/modal/BankInfoModal.vue";
import DepositModal from "@/components/modal/DepositModal.vue";
import WithdrawalModal from "@/components/modal/WithdrawalModal.vue";
import InterchangeModal from "@/components/modal/InterchangeModal.vue";
import GameIDInfoModal from "@/components/modal/GameIDInfoModal.vue";

export default defineComponent({
  data() {
    return {
      logoUrl: "spribe/assets/images/NavBar/spribe.png",
      logoWhiteUrl: "spribe/assets/images/NavBar/spribe_white.png",
      scrollPosition: 0,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    isMobile() {
      return this.$isMobile;
    },
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  components: {
    HeaderNavBar,
    HeaderLogin,
    MobileSubHeader,
    BaseImage,
    SideBar,
    ForgotPasswordModal,
    SignUpModal,
    LoginModal,
    ChangePasswordModal,
    ProfileModal,
    BankInfoModal,
    DepositModal,
    WithdrawalModal,
    InterchangeModal,
    GameIDInfoModal,
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
});
</script>
