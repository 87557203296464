<template>
	<div class="homeGoalContainer">
		<b-container>
			<div class="homeGoalContent">
				<div class="homeGoalItemContainer" v-for="homeGoalItem in homeAdvantage" :key="homeGoalItem">
					<div class="homeGoalHeader">
						<div class="homeGoalTitle">{{ $t("HomeGoal." + homeGoalItem.Name) }}</div>
						<i18n-t tag="div" :keypath="'HomeGoal.' + homeGoalItem.Name + 'Desc'" class="textDesc">
							<template #newline><br /></template>
						</i18n-t>
					</div>
					<div class="homeGoalImg">
						<base-image :imgSrc="homeGoalItem.Img"></base-image>
					</div>
				</div>
			</div>
		</b-container>
	</div>
</template>
<style scoped>
.homeGoalContainer {
	background: #e4e4ff66;
	padding: 4rem 0px;
}
.homeGoalContent {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.homeGoalItemContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.homeGoalItemContainer:nth-child(even) {
	flex-direction: column-reverse;
}
.homeGoalItemContainer .homeGoalHeader {
	padding: 2rem 0px;
}
.homeGoalItemContainer .homeGoalTitle {
	color: #2b2b8b;
	font-size: 1.5rem;
	font-weight: bold;
}
.homeGoalItemContainer .textDesc {
	color: #505050;
}
.homeGoalContainer .homeGoalImg {
	padding: 0 3rem;
}

@media (max-width: 767px) {
	.homeGoalContent {
		flex-direction: column;
	}
	.homeGoalItemContainer {
		margin: 2rem 0px;
	}
	.homeGoalItemContainer:nth-child(even) {
		flex-direction: column;
	}
	.homeGoalContainer .homeGoalImg {
		padding: 0 5rem;
	}
}
</style>
<script>
export default {
	data() {
		return {
			depositTime: 5,
			withdrawalTime: 15,
			homeAdvantage: [
				{
					Name: "FastProcessingTime",
					Img: "spribe/assets/images/Home/advantage/fastProcessing.png",
				},
				{
					Name: "CustomerService",
					Img: "spribe/assets/images/Home/advantage/customer.png",
				},
				{
					Name: "SafeSecure",
					Img: "spribe/assets/images/Home/advantage/securePayment.png",
				},
			],
		};
	},
};
</script>
