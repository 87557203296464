<template>
  <b-container class="homeSpribeContainer">
    <div class="HomeTitle">{{ $t("HomeSpribeGame.OurGames") }}</div>

    <Carousel
      v-if="gameList.length !== 0 && renderCarousel"
      id="homeSpribeGame"
      class="bannerMax"
      :autoplay="4000"
      :wrap-around="true"
      pause-autoplay-on-hover
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <slide v-for="spribeGame in gameList" :key="spribeGame">
        <div class="homeSpribeGameContent">
          <BaseImage
            :imgSrc="spribeGamePath + spribeGame.gameImage"
            mode="spribeGameImg"
            :isFullUrl="true"
            fluid-grow
          ></BaseImage>
          <div class="gameContent">
            <div class="gameTitle">
              <span>{{ spribeGame.gameName }}</span>
            </div>
            <div class="btn-playNow" v-if="!isLogin" v-b-modal="'login-modal'">
              <span>{{ $t("HomeSpribeGame.PlayNow") }}</span>
              <font-awesome-icon :icon="['fas', 'arrow-right']" class="goIcon" />
            </div>
            <div class="btn-playNow" @click="playGame(spribeGame.gameCode)" v-else>
              <span>{{ $t("HomeSpribeGame.PlayNow") }}</span>
              <font-awesome-icon :icon="['fas', 'arrow-right']" class="goIcon" />
            </div>
          </div>
        </div>
      </slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { getDefaultCultureCode } from "@/currentCulture";
import { GameType } from "@/common/GameType.ts";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    BaseImage,
  },
  data() {
    return {
      spribeGamePath: process.env.VUE_APP_Media_CentralizedDomain_Domain + "VendorGameImage/spribe/",
      gameCategory: [],
      gameList: [],
      // spribeGameList: [
      // 	{
      // 		title: "Aviator",
      // 		img: "spribe_aviator.png",
      // 		rating: 4.5,
      // 	},
      // 	{
      // 		title: "Dice",
      // 		img: "spribe_dice.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Hilo",
      // 		img: "spribe_hilo.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Miner",
      // 		img: "spribe_miner.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Plinko",
      // 		img: "spribe_plinko.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Goal",
      // 		img: "spribe_goal.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Keno",
      // 		img: "spribe_keno.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Hotline",
      // 		img: "spribe_hotline.png",
      // 		rating: 4,
      // 	},
      // 	{
      // 		title: "Mini Roulette",
      // 		img: "spribe_miniroulette.png",
      // 		rating: 4,
      // 	},
      // ],
      settings: {
        itemsToShow: 1.5,
        snapAlign: "center",
      },
      breakpoints: {
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
      },
      renderCarousel: false,
    };
  },
  methods: {
    async getCategory() {
      await this.$store.dispatch("games/fetchGameCategory").then((category) => {
        this.gameCategory = category;
        this.getGameList();
      });
    },
    async getGameList() {
      await this.$store.dispatch("games/fetchGameList", { GameCategory: this.gameCategory[0] }).then(() => {
        this.gameList = this.$store.getters["games/getGameList"];
        this.renderCarousel = false;
        this.$nextTick(() => {
          this.renderCarousel = true;
        });
      });
    },
    async playGame(gameCode) {
      const currentLocaleID = getDefaultCultureCode();
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", {
          clientName: this.getClientName,
          GameType: GameType.SLOTS,
          Lang: currentLocaleID,
          gameCode: gameCode,
          isMobile: this.$isMobile,
        })
        .then((url) => {
          loader.hide();
          if (url) {
            window.open(url, "blank");
          } else {
            this.toast.error(this.$t("HomeSpribeGame.GameMaintance"), {
              position: this.toastPosition,
            });
          }
        })
        .catch((err) => {
          loader.hide();
          this.toast.error(this.$t("HomeSpribeGame.GameMaintance"), {
            position: this.toastPosition,
          });
        });
    },
    async fetchClientInfo() {
      await this.$store.dispatch("client/fetchClient");
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    vendorList() {
      return this.$store.getters["vendors/getVendor"];
    },
    getClientName() {
      return this.$store.getters["client/getClientInfo"].clientName;
    },
  },
  watch: {
    vendorList() {
      this.getCategory();
      this.fetchClientInfo();
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>
<style scoped>
.homeSpribeContainer {
  margin: 4rem auto 0 auto;
}
.HomeTitle {
  font-size: 2.1rem;
  padding: 2rem 0px;
  color: #2b2b8b;
  font-weight: bold;
}
.gameContent {
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0 0 1rem 1rem;
}
.spribeGameImg {
  border-radius: 1rem 1rem 0 0;
}
.gameTitle {
  color: #2b2b8b;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.ratingContainer {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #ffcc00;
}

.btn-playNow {
  color: #d80027;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.goIcon {
  margin-left: 0.5rem;
  border-radius: 50%;
  border: 1px solid #cccccc;
  background: #ffffff;
  padding: 0.25rem;
}

@media (max-width: 767px) {
  .homeSpribeContainer {
    margin: 0 auto 0 auto;
  }
}
</style>
<style>
#homeSpribeGame .carousel__slide {
  align-items: end;
}
#homeSpribeGame .carousel__slide .homeSpribeGameContent {
  transform: scale(0.85);
  transition: all 0.2s linear;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#homeSpribeGame .carousel__slide.carousel__slide--active .homeSpribeGameContent {
  transform: scale(1);
}

#homeSpribeGame .carousel__pagination {
  margin: unset;
  bottom: -1rem;
}
#homeSpribeGame .carousel__pagination-button {
  background: #2b2b8b22;
  transition: all 0.75s linear;
  width: 0.5rem;
  height: 0.3rem;
  padding: 0.1rem;
}
#homeSpribeGame .carousel__pagination-button.carousel__pagination-button--active {
  background: #2b2b8bff;
  width: 2rem;
}

@media (max-width: 767px) {
  #homeSpribeGame .carousel__pagination {
    overflow-x: hidden;
  }
}
</style>
