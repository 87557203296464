<template>
	<b-container fluid class="footer-row">
		<b-container class="py-4">
			<div class="footer-grid-container">
				<div class="joinUs-container">
					<div class="socialIcon" v-for="listItem in socialMediaList" :key="listItem">
						<base-image :imgSrc="listItem.img" :title="listItem.title" @click="openUrl(listItem.url)"></base-image>
					</div>
				</div>
				<div class="eighteen-container">
					<div cols="3">
						<div class="eighteenText">18+</div>
					</div>
					<div cols="9" class="gamblingText">
						{{ $t("TheFooter.Gamblingcanbeaddictive") }},<br />{{ $t("TheFooter.Playresponsibly") }}
					</div>
				</div>

				<div class="information-container">
					<div class="informationText">
						<router-link :to="'/aboutus'">
							<b-col class="my-2">{{ $t("TheFooter.About") }}</b-col>
						</router-link>
						<router-link :to="'/terms'">
							<b-col class="my-2">{{ $t("TheFooter.Terms") }}</b-col>
						</router-link>
						<router-link :to="'/privacypolicy'">
							<b-col class="my-2">{{ $t("TheFooter.Privacy") }}</b-col>
						</router-link>
						<router-link :to="'/responsiblegaming'">
							<b-col class="my-2">{{ $t("TheFooter.ResponsibleGaming") }}</b-col>
						</router-link>
						<router-link :to="'/contact'">
							<b-col class="my-2">{{ $t("TheFooter.ContactUs") }}</b-col>
						</router-link>
					</div>
				</div>

				<!-- <div class="talkToUs-container">
          <div class="d-flex justify-content-end w-100 my-4">
            <div>
              <b-button variant="talkToUs">{{ $t("TheFooter.TalktoUs") }}</b-button>
            </div>
          </div>
        </div> -->
				<div class="copyRight-container">
					<div class="my-2">{{ $t("TheFooter.Copyright") }} © 2023. {{ $t("TheFooter.AllRightsReserved") }}</div>
				</div>

				<div class="break-line"></div>
			</div>
		</b-container>
	</b-container>
</template>

<style scoped>
.footer-row {
	font-size: 14px;
	font-weight: 700;
	font-family: "Lato";
	color: #000000;
	background: #eaeaea;
}
.informationText {
	display: flex;
	width: 100%;
	justify-content: start;
}
.informationText > a {
	color: #000000 !important;
	white-space: nowrap;
	text-decoration: none !important;
	margin: 0 1rem;
}
.eighteenText {
	border-radius: 100%;
	border: 2px solid #ff0000;
	width: 100%;
	height: auto;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.gamblingText {
	font-size: 8px;
	font-weight: bold;
	font-family: "Lato";
	text-align: start;
	padding-left: 1rem;
}
.btn-talkToUs {
	background: linear-gradient(104.86deg, #9f4141 0%, #581b1a 100%);
	border-radius: 100px 100px 0px 100px !important;
	border: none;
}

.footer-grid-container {
	display: grid;
	grid-template-areas:
		"infor infor infor infor . . joins"
		"bline bline bline bline bline bline bline"
		"eight eight eight . . copys copys";
	grid-auto-columns: 20% 1fr 20%;
}
.joinUs-container {
	grid-area: joins;
	display: flex;
	justify-content: end;
}
.information-container {
	grid-area: infor;
}
.talkToUs-container {
	grid-area: talks;
	display: flex;
}
.copyRight-container {
	grid-area: copys;
	display: flex;
	justify-content: end;
}
.socialIcon {
	margin: 0 0.2rem;
	cursor: pointer;
}
.eighteen-container {
	grid-area: eight;
	display: flex;
}
.break-line {
	width: 100%;
	height: 1px;
	background: #000000;
	margin: 1rem 0;
	grid-area: bline;
}
@media (max-width: 767px) {
	.footer-grid-container {
		grid-template-areas:
			"eight eight . joins joins"
			"infor infor infor infor infor"
			"bline bline bline bline bline"
			". copys copys copys .";
	}
	.informationText {
		justify-content: center;
		flex-wrap: wrap;
		margin: 1rem 0;
	}
	.footer-row {
		padding-bottom: 70px;
	}
	.copyRight-container {
		justify-content: center;
	}
	.eighteenText {
		padding: 0.25rem;
		font-size: 0.8rem;
	}
}
</style>

<script>
export default {
	data() {
		return {
			socialMediaList: [
				{
					title: "Facebook",
					img: "spribe/assets/images/footer/facebook.png",
					url: "https://facebook.com",
				},
				{
					title: "Instagram",
					img: "spribe/assets/images/footer/instagram.png",
					url: "",
				},
				{
					title: "WhatsApp",
					img: "spribe/assets/images/footer/whatsapp.png",
					url: "",
				},
				{
					title: "YouTube",
					img: "spribe/assets/images/footer/youtube.png",
					url: "",
				},
			],
		};
	},
	methods: {
		openUrl(url) {
			if (url) {
				window.open(url, "_blank");
			}
		},
	},
};
</script>
