// import { createStore } from "vuex";
// import GlobalActions from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { announcement } from "./AnnouncementModule/index";
import { bank } from "./BankModule/index";
import { banner } from "./BannerModule/index";
import { bonus } from "./BonusModule/index";
import { brand } from "./BrandModule/index";
import { client } from "./ClientModule/index";
import { downloadLinks } from "./DownloadLinksModule/index";
import { games } from "./GamesModule/index";
import { globalSetting } from "./GlobalSettingModule/index";
import { identityServer } from "./IdentityServerModule/index";
import { ipAddress } from "./IpAddressModule/index";
import { language } from "./LanguageModule/index";
import { memberAccount } from "./MemberAccountModule/index";
import { member } from "./MemberModule/index";
import { payment } from "./PaymentModule/index";
import { register } from "./RegisterModule/index";
import { tenant } from "./TenantModule/index";
import { vendors } from "./VendorsModule/index";
import { winLosses } from "./WinLossesModule/index";
import { paymentchannel } from "./PaymentChannelModule/index";
import { rebate } from "./RebateModule/index";
import { miniGames } from "./MiniGamesModule/index";

import Vuex, { StoreOptions } from "vuex";
import state, { RootState } from "@/store/state";

const store: StoreOptions<RootState> = {
  state: state,
  mutations,
  getters,
  modules: {
    announcement,
    bank,
    banner,
    bonus,
    brand,
    client,
    downloadLinks,
    games,
    globalSetting,
    identityServer,
    ipAddress,
    language,
    memberAccount,
    member,
    payment,
    register,
    tenant,
    vendors,
    winLosses,
    paymentchannel,
    rebate,
    miniGames,
  },
};

export default new Vuex.Store<RootState>(store);
