<template>
  <b-modal
    id="forgotPassword-modal"
    class="default-modal"
    size="md"
    hide-footer
    centered
    data-backdrop="static"
    :title="$t('ForgotPasswordModal.ForgotPassword')"
    v-model="ForgotPasswordModalShow"
  >
    <b-container class="px-5">
      <b-row>
        <b-col cols="12">
          <b-form name="forgotForm">
            <b-input-group class="mt-3" prepend="+60">
              <b-form-input
                v-model="forgotForm.phoneNo"
                :placeholder="$t('ForgotPasswordModal.PhoneNumber')"
                type="text"
                required
                inputmode="numeric"
              >
              </b-form-input>
            </b-input-group>
            <div class="input-errors" v-for="(error, index) of v$.forgotForm.phoneNo.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mx-0 my-3 pt-4">
        <b-button variant="main" block @click="submitForgotPasswordForm">
          {{ $t("ForgotPasswordModal.Submit") }}
        </b-button>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, numeric, maxLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      forgotForm: {
        phoneNo: {
          required: helpers.withMessage(() => this.$t("ForgotPasswordModal.PhoneRequired"), required),
          numeric: helpers.withMessage(() => this.$t("ForgotPasswordModal.PhoneNumeric"), numeric),
          minLength: helpers.withMessage(
            ({ $params }) => this.$t("ForgotPasswordModal.PhoneMin", { minLength: $params.min }),
            minLength(9)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) => this.$t("ForgotPasswordModal.PhoneMax", { maxLength: $params.max }),
            maxLength(10)
          ),
        },
      },
    };
  },
  data() {
    return {
      forgotForm: {
        phoneNo: "",
      },
      phoneMin: 9,
      phoneMax: 10,
      ForgotPasswordModalShow: false,
    };
  },
  computed: {},
  components: {},
  methods: {
    async submitForgotPasswordForm() {
      const toast = useToast();
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      await this.$store
        .dispatch("member/forgotPassword", this.forgotForm)
        .then(() => {
          loader.hide();
          toast.success(this.$t("ForgotPasswordModal.SubmitSuccess"), { position: toastPosition });
          this.hideModal();
        })
        .catch(() => {
          loader.hide();
          toast.error(this.$t("ForgotPasswordModal.SubmitFailed"), { position: toastPosition });
        });
    },
    hideModal() {
      this.ForgotPasswordModalShow = false;
    },
  },
};
</script>
