import { ActionTree } from "vuex";
import { getClientInfo } from "@/network/ClientService/Client";
import { ClientStateInterface } from "./state";
import { RootState } from "@/store/state";
import { ClientMutations } from "./mutations";

export const actions: ActionTree<ClientStateInterface, RootState> = {
	async fetchClient(context) {
		const res = await getClientInfo();
		context.commit(ClientMutations.SET_CLIENT_INFO, res);
	},
};
