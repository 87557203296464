export default {
	SignUp: "Daftar",
	Login: "Log Masuk",
	Hi: "Hai",
	Deposit: "Deposit",
	LoginID: "Login ID",
	LogoutSuccess: "Berjaya Log Keluar",
	Player: "Pemain",
	GameMaintenance: "Permainan sedang diselenggara. Sila cuba sebentar lagi.",
	en: "English",
	zh: "简体中文",
	ms: "B. Melayu",
};
