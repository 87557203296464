export default {
	ForgotPassword: "忘记密码",
	PhoneNumber: "电话号码",
	Submit: "确认",
	PhoneRequired: "*请填写手机号码",
	PhoneNumeric: "*手机号码必须是数字",
	PhoneMin: "*手机号码必须至少{minLength}位数",
	PhoneMax: "*手机号码必须最多{maxLength}位数",
	SubmitSuccess: "成功提交",
	SubmitFailed: "提交失敗",
};
