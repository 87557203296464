// Header
import HeaderLogin from "@/resource/components/content/Header/HeaderLogin.en";
import HeaderNavLink from "@/resource/components/content/Header/HeaderNavLink.en";
import SideBar from "@/resource/components/content/Header/SideBar.en";
import MobileSubHeader from "@/resource/components/content/Header/MobileSubHeader.en";

//Home
import HomeDownload from "@/resource/components/content/Home/HomeDownload.en";
import HomeFastPayment from "@/resource/components/content/Home/HomeFastPayment.en";
import HomeHotSlot from "@/resource/components/content/Home/HomeHotSlot.en";
import HomeLiveCasino from "@/resource/components/content/Home/HomeLiveCasino.en";
import HomeJackpot from "@/resource/components/content/Home/HomeJackpot.en";
import HomePromotion from "@/resource/components/content/Home/HomePromotion.en";
import HomeHistoryCompany from "@/resource/components/content/Home/HomeHistoryCompany.en";
import HomeSwitchSite from "@/resource/components/content/Home/HomeSwitchSite.en";
import HomeSpribeGame from "@/resource/components/content/Home/HomeSpribeGame.en";
import HomeAviatorDice from "@/resource/components/content/Home/HomeAviatorDice.en";
import HomeGoal from "@/resource/components/content/Home/HomeGoal.en";
import HomeAbout from "@/resource/components/content/Home/HomeAbout.en";

//Mobile Footer
import MobileFooter from "@/resource/components/content/MobileFooter/MobileFooter.en";

// Announcement
import RunningTextModal from "@/resource/components/modal/RunningTextModal.en";
import EventView from "@/resource/components/content/Event/EventView.en";

// Download
import DownloadAccount from "@/resource/components/content/Download/DownloadAccount.en";
import DownloadQR from "@/resource/components/content/Download/DownloadQR.en";

// Promotion
import PromotionItem from "@/resource/components/content/Promotion/PromotionItem.en";
import PromotionPage from "@/resource/pages/promotion/PromotionPage.en";

// VIP
import VipPage from "@/resource/pages/vip/VipPage.en";

//Contact
import ContactPage from "@/resource/pages/contact/ContactPage.en";

//Information
import InformationHeader from "@/resource/components/content/Information/InformationHeader.en";

// Rank
import RankPage from "@/resource/pages/rank/RankPage.en";

//Statement
import StatementPage from "@/resource/pages/fund/StatementPage.en";
import DepositItem from "@/resource/components/content/Fund/DepositItem.en";
import WithdrawalItem from "@/resource/components/content/Fund/WithdrawalItem.en";
import TransferItem from "@/resource/components/content/Fund/TransferItem.en";
import BetHistoryItem from "@/resource/components/content/Fund/BetHistoryItem.en";
import BonusItem from "@/resource/components/content/Fund/BonusItem.en";
import AdjustmentItem from "@/resource/components/content/Fund/AdjustmentItem.en";

//Games
import GamesPage from "@/resource/pages/games/GamesPage.en";

// Member
import ForgotPasswordPage from "@/resource/pages/member/ForgotPassword.en";

//Referral
import ReferralPage from "@/resource/pages/fund/ReferralPage.en";

// Footer
import TheFooter from "@/resource/components/layout/TheFooter.en";
import AboutUsPage from "@/resource/pages/information/AboutUsPage.en";
import TermsPage from "@/resource/pages/information/TermsPage.en";
import PrivacyPolicyPage from "@/resource/pages/information/PrivacyPolicyPage.en";
import ResponsibleGamingPage from "@/resource/pages/information/ResponsibleGamingPage.en";

//Modal
import LoginModal from "@/resource/components/modal/LoginModal.en";
import SignUpModal from "@/resource/components/modal/SignUpModal.en";
import ForgotPasswordModal from "@/resource/components/modal/ForgotPasswordModal.en";
import ChangePasswordModal from "@/resource/components/modal/ChangePasswordModal.en";
import ProfileModal from "@/resource/components/modal/ProfileModal.en";
import BankInfoModal from "@/resource/components/modal/BankInfoModal.en";
import DepositModal from "@/resource/components/modal/DepositModal.en";
import WithdrawalModal from "@/resource/components/modal/WithdrawalModal.en";
import InterchangeModal from "@/resource/components/modal/InterchangeModal.en";
import GameIDInfoModal from "@/resource/components/modal/GameIDInfoModal.en";

//Signalr
import TheSignalr from "@/resource/components/layout/TheSignalr.en";

//Floating Switch Site
import FloatingSwitchSite from "@/resource/components/content/FloatingSwitchSite/FloatingSwitchSite.en";

export default {
  HeaderLogin,
	HeaderNavLink,
	SideBar,
	MobileSubHeader,
	HomeDownload,
	HomeFastPayment,
	HomeHotSlot,
	HomeLiveCasino,
	HomeJackpot,
	HomePromotion,
	HomeHistoryCompany,
	HomeSwitchSite,
	HomeSpribeGame,
	HomeAviatorDice,
	HomeGoal,
	HomeAbout,
	MobileFooter,
	RunningTextModal,
	EventView,
	DownloadAccount,
	DownloadQR,
	PromotionItem,
	PromotionPage,
	ContactPage,
	InformationHeader,
	RankPage,
  VipPage,
	ForgotPasswordPage,
	AboutUsPage,
	TermsPage,
	PrivacyPolicyPage,
	ResponsibleGamingPage,
	StatementPage,
	DepositItem,
	WithdrawalItem,
	TransferItem,
	GamesPage,
	ReferralPage,
	TheFooter,
	LoginModal,
	SignUpModal,
	ForgotPasswordModal,
	ChangePasswordModal,
	ProfileModal,
	BankInfoModal,
	DepositModal,
	WithdrawalModal,
	InterchangeModal,
	GameIDInfoModal,
	BetHistoryItem,
	BonusItem,
	AdjustmentItem,
	TheSignalr,
	FloatingSwitchSite,
};
