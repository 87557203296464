<template>
  <b-container fluid v-if="isLogin" class="subHeaderContainer d-block d-md-none">
    <b-container>
      <div class="subHeaderContent">
        <div class="d-flex">
          <div class="mx-1">{{ $t("MobileSubHeader.GameID") }}</div>
          <div class="mx-1">{{ getVendorGameID }}</div>
        </div>
        <div class="d-flex" @click="refreshWallet">
          <div class="mx-1">{{ $t("MobileSubHeader.GameBalance") }} :</div>
          <div class="mx-1">RM {{ getBalance }}</div>
          <div class="mx-1">
            <span>
              <font-awesome-icon :icon="['fas', 'arrows-rotate']" :class="{ 'fa-spin': isSpin }" class="ms-1">
              </font-awesome-icon>
            </span>
          </div>
        </div>
      </div>
    </b-container>
  </b-container>
</template>
<style scoped>
.subHeaderContainer {
  background: #252525;
  padding: unset;
  color: #ffffff;
  transition: background 0.5s;
}
.isNotTop .subHeaderContainer {
  background: #ffffff;
  color: #000000;
}
.subHeaderContent {
  height: 30px;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<script>
export default {
  data() {
    return {
      vendorAmount: 0,
      isSpin: false,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    getMemberID() {
      return this.$store.getters["identityServer/getLoginId"];
    },
    getVendorGameID() {
      return this.$store.getters["memberAccount/getMemberAccount"].vendorGameId;
    },
    getBalance() {
      return this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
  },
  methods: {
    async refreshWallet() {
      if (this.isMaintenance) {
        this.maintenancepopup();
      } else {
        this.isSpin = true;
        await this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false }).then(() => {
          this.isSpin = false;
        });
        const latestBalance = this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
        const memberWinover = this.$store.getters["memberAccount/getMemberWinover"];
        if (memberWinover.winoverBalances.length > 0) {
          const latestWinover = memberWinover.winoverBalances[0].winover;
          const group = memberWinover.winoverBalances[0].category;
          const lockdown = memberWinover.winoverBalances[0].lockdown;
          if (latestWinover > 0) {
            await this.getVedorCategoryList(latestBalance, group, latestWinover, lockdown);
          }
        }
        this.balance;
      }
    },
    async fetchMemberAccount() {
      await this.$store.dispatch("memberAccount/fetchMemberAccount");
    },
    async fetchMemberWinover() {
      await this.$store.dispatch("memberAccount/fetchMemberWinover", { loginId: this.getLoginId, isFromDB: true });
    },
    async getVedorCategoryList(latestBalance, group, latestWinover, lockdown) {
      await this.$store.dispatch("vendors/fetchCategoryList").then(() => {
        const vendorCategory = this.$store.getters["vendors/getCategory"];
        if (latestBalance <= vendorCategory.resetThreshold) {
          this.resetWinover(group, latestWinover, lockdown);
          this.fetchMemberWinover();
        }
      });
    },
    async resetWinover(group, latestWinover, lockdown) {
      await this.$store.dispatch("memberAccount/resetWinover", {
        group: group,
        winover: latestWinover,
        lockdown: lockdown,
      });
    },
    async maintenancepopup() {
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
  watch: {
    getMemberID(newVal) {
      if (newVal !== "") {
        this.fetchMemberAccount();
      }
    },
  },
};
</script>
