// Header
import HeaderLogin from "@/resource/components/content/Header/HeaderLogin.ms";
import HeaderNavLink from "@/resource/components/content/Header/HeaderNavLink.ms";
import SideBar from "@/resource/components/content/Header/SideBar.ms";
import MobileSubHeader from "@/resource/components/content/Header/MobileSubHeader.ms";

//Home
import HomeDownload from "@/resource/components/content/Home/HomeDownload.ms";
import HomeFastPayment from "@/resource/components/content/Home/HomeFastPayment.ms";
import HomeHotSlot from "@/resource/components/content/Home/HomeHotSlot.ms";
import HomeLiveCasino from "@/resource/components/content/Home/HomeLiveCasino.ms";
import HomeJackpot from "@/resource/components/content/Home/HomeJackpot.ms";
import HomePromotion from "@/resource/components/content/Home/HomePromotion.ms";
import HomeHistoryCompany from "@/resource/components/content/Home/HomeHistoryCompany.ms";
import HomeSwitchSite from "@/resource/components/content/Home/HomeSwitchSite.ms";
import HomeSpribeGame from "@/resource/components/content/Home/HomeSpribeGame.ms";
import HomeAviatorDice from "@/resource/components/content/Home/HomeAviatorDice.ms";
import HomeGoal from "@/resource/components/content/Home/HomeGoal.ms";
import HomeAbout from "@/resource/components/content/Home/HomeAbout.ms";

//Mobile Footer
import MobileFooter from "@/resource/components/content/MobileFooter/MobileFooter.ms";

// Announcement
import RunningTextModal from "@/resource/components/modal/RunningTextModal.ms";
import EventView from "@/resource/components/content/Event/EventView.ms";

// Download
import DownloadAccount from "@/resource/components/content/Download/DownloadAccount.ms";
import DownloadQR from "@/resource/components/content/Download/DownloadQR.ms";

// Promotion
import PromotionItem from "@/resource/components/content/Promotion/PromotionItem.ms";
import PromotionPage from "@/resource/pages/promotion/PromotionPage.ms";

// VIP
import VipPage from "@/resource/pages/vip/VipPage.ms";

//Contact
import ContactPage from "@/resource/pages/contact/ContactPage.ms";

//Information
import InformationHeader from "@/resource/components/content/Information/InformationHeader.ms";

// Rank
import RankPage from "@/resource/pages/rank/RankPage.ms";

// Member
import ForgotPasswordPage from "@/resource/pages/member/ForgotPassword.ms";

//Statement
import StatementPage from "@/resource/pages/fund/StatementPage.ms";
import DepositItem from "@/resource/components/content/Fund/DepositItem.ms";
import WithdrawalItem from "@/resource/components/content/Fund/WithdrawalItem.ms";
import TransferItem from "@/resource/components/content/Fund/TransferItem.ms";
import BetHistoryItem from "@/resource/components/content/Fund/BetHistoryItem.ms";
import BonusItem from "@/resource/components/content/Fund/BonusItem.ms";
import AdjustmentItem from "@/resource/components/content/Fund/AdjustmentItem.ms";

//Games
import GamesPage from "@/resource/pages/games/GamesPage.ms";

//Referral
import ReferralPage from "@/resource/pages/fund/ReferralPage.ms";

// Footer
import TheFooter from "@/resource/components/layout/TheFooter.ms";
import AboutUsPage from "@/resource/pages/information/AboutUsPage.ms";
import TermsPage from "@/resource/pages/information/TermsPage.ms";
import PrivacyPolicyPage from "@/resource/pages/information/PrivacyPolicyPage.ms";
import ResponsibleGamingPage from "@/resource/pages/information/ResponsibleGamingPage.ms";

//Modal
import LoginModal from "@/resource/components/modal/LoginModal.ms";
import SignUpModal from "@/resource/components/modal/SignUpModal.ms";
import ForgotPasswordModal from "@/resource/components/modal/ForgotPasswordModal.ms";
import ChangePasswordModal from "@/resource/components/modal/ChangePasswordModal.ms";
import ProfileModal from "@/resource/components/modal/ProfileModal.ms";
import BankInfoModal from "@/resource/components/modal/BankInfoModal.ms";
import DepositModal from "@/resource/components/modal/DepositModal.ms";
import WithdrawalModal from "@/resource/components/modal/WithdrawalModal.ms";
import InterchangeModal from "@/resource/components/modal/InterchangeModal.ms";
import GameIDInfoModal from "@/resource/components/modal/GameIDInfoModal.ms";

//Signalr
import TheSignalr from "@/resource/components/layout/TheSignalr.ms";

//Floating Switch Site
import FloatingSwitchSite from "@/resource/components/content/FloatingSwitchSite/FloatingSwitchSite.ms";

export default {
	HeaderLogin,
	HeaderNavLink,
	SideBar,
	MobileSubHeader,
	HomeDownload,
	HomeFastPayment,
	HomeHotSlot,
	HomeLiveCasino,
	HomeJackpot,
	HomePromotion,
	HomeHistoryCompany,
	HomeSwitchSite,
	HomeSpribeGame,
	HomeAviatorDice,
	HomeGoal,
	HomeAbout,
	MobileFooter,
	RunningTextModal,
	EventView,
	DownloadAccount,
	DownloadQR,
	PromotionItem,
	PromotionPage,
	ContactPage,
	InformationHeader,
	RankPage,
  VipPage,
	ForgotPasswordPage,
	AboutUsPage,
	TermsPage,
	PrivacyPolicyPage,
	ResponsibleGamingPage,
	StatementPage,
	DepositItem,
	WithdrawalItem,
	TransferItem,
	GamesPage,
	ReferralPage,
	TheFooter,
	LoginModal,
	SignUpModal,
	ForgotPasswordModal,
	ChangePasswordModal,
	ProfileModal,
	BankInfoModal,
	DepositModal,
	WithdrawalModal,
	InterchangeModal,
	GameIDInfoModal,
	BetHistoryItem,
	BonusItem,
	AdjustmentItem,
	TheSignalr,
	FloatingSwitchSite,
};
