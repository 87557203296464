export default {
	Home: "主页",
	Games: "游戏",
	Download: "下载",
	Promotion: "优惠",
	Rank: "排行",
	"Contact Us": "联系我们",
	Language: "语言",
	Deposit: "存款",
	Withdrawal: "提款",
	"Change Password": "更改密码",
	Logout: "登出",
	LogoutSuccess: "成功登出",
	Profile: "个人资料",
	"Bank Info": "银行信息",
	Statement: "帐单",
	Referral: "转介人",
	Interchange: "切换我",
	GameIDInfo: "游戏账户资料",
	SLOT: "老虎机",
	LIVECASINO: "线上赌场",
  VIP: "VIP",
};
