export default {
	Home: "Home",
	Games: "Games",
	Download: "Download",
	Promotion: "Promotion",
	Rank: "Ranking",
	"Contact Us": "Contact Us",
	Language: "Language",
	Deposit: "Deposit",
	Withdrawal: "Withdrawal",
	"Change Password": "Change Password",
	Logout: "Logout",
	LogoutSuccess: "Logout Success",
	Profile: "Profile",
	"Bank Info": "Bank Info",
	Statement: "Statement",
	Referral: "Referral",
	Interchange: "Switch Me",
	GameIDInfo: "Game ID Info",
	SLOT: "Slot",
	LIVECASINO: "Live Casino",
  VIP: "VIP",
};
