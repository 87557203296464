<template>
  <div v-for="navbar in navbarList" :key="navbar.title">
    <template v-if="isMaintenance && !navbar.ignoreMaintenance">
      <div class="navLink" @click="maintenancepopup" v-if="isLogin">
        <svg class="navIcon" v-html="navbar.svgIcon" v-if="navbar.svgIcon"></svg>
        <span class="navTitle">{{ $t("HeaderNavLink." + navbar.title) }}</span>
      </div>
      <div class="navLink" v-b-modal="'login-modal'" v-else>
        <svg class="navIcon" v-html="navbar.svgIcon" v-if="navbar.svgIcon"></svg>
        <span class="navTitle">{{ $t("HeaderNavLink." + navbar.title) }}</span>
      </div>
    </template>
    <template v-else-if="navbar.isRouteLink === true && navbar.isPopUp === false">
      <router-link :to="navbar.link">
        <div class="navLink">
          <svg class="navIcon" v-html="navbar.svgIcon" v-if="navbar.svgIcon"></svg>
          <span class="navTitle">{{ $t("HeaderNavLink." + navbar.title) }}</span>
        </div>
      </router-link>
    </template>
    <template v-else>
      <div class="navLink" @click="doAction(navbar.link)" v-if="isLogin">
        <svg class="navIcon" v-html="navbar.svgIcon" v-if="navbar.svgIcon"></svg>
        <span class="navTitle">{{ $t("HeaderNavLink." + navbar.title) }}</span>
      </div>
      <div class="navLink" v-b-modal="'login-modal'" v-else>
        <svg class="navIcon" v-html="navbar.svgIcon" v-if="navbar.svgIcon"></svg>
        <span class="navTitle">{{ $t("HeaderNavLink." + navbar.title) }}</span>
      </div>
    </template>
  </div>
</template>

<style scoped>
.navIconContent {
  background: #3e0d0c;
  box-shadow: -2px -5px 10px #801b19;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.navLink:hover,
.router-link-active .navLink,
.router-link-exact-active .navLink {
  border-bottom: 2px solid #2b2b8b;
}

.isNotTop .navLink:hover,
.isNotTop .router-link-active .navLink,
.isNotTop .router-link-exact-active .navLink {
  border-bottom: 2px solid #ffcc00;
}

.navIcon {
  width: 2rem;
  height: 2rem;
  fill: #000000;
}

.isNotTop .navIcon {
  fill: #ffffff;
}

.navTitle {
  margin-left: 0.5rem;
  white-space: nowrap;
}

.navIconContent:hover .navIcon,
.router-link-active .navIcon,
.router-link-exact-active .navIcon {
  color: #2b2b8b;
  fill: #2b2b8b;
}

.isNotTop .navIconContent:hover .navIcon,
.isNotTop .router-link-active .navIcon,
.isNotTop .router-link-exact-active .navIcon {
  color: #ffcc00;
  fill: #ffcc00;
}

.isNotTop .router-link-active .navTitle {
  color: #ffcc00;
}

.navLink {
  color: #000000;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  margin: 0px 1.5vw;
  cursor: pointer;
  padding: 1vw 0.5vw 1vw 0.5vw;
  display: flex;
  align-items: center;
}
.isNotTop .navLink {
  color: #ffffff;
}

a,
a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .navLink {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .navLink {
    padding-top: 2px;
  }

  .navIconContent {
    box-shadow: -2px -2px 10px #801b19;
  }

  .navIconContent:hover,
  .router-link-active .navIconContent,
  .router-link-exact-active .navIconContent {
    box-shadow: -2px -2px 10px #f38f8c;
  }
}
</style>

<script>
import { navBar } from "@/constant";
import { GameType } from "@/common/GameType.ts";

export default {
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    navbarList() {
      var navBarFiltered = navBar.filter((i) => i.view.includes("pc"));
      if (!this.isLogin) {
        navBarFiltered = navBarFiltered.filter((i) => i.afterLogin == false);
      }
      return navBarFiltered;
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
  },
  methods: {
    doAction(action) {
      if (action == "slot") {
        this.playSlot();
      } else if (action == "casino") {
        this.playCasino();
      }
    },
    async playSlot() {
      let loader = this.$loading.show();
      await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" }).then(() => {
        loader.hide();
        const tempGameUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
        window.open(tempGameUrl, "_blank");
      });
      // await this.$store
      // 	.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
      // 	.then((url) => {
      // 		window.open(url, "_blank");
      // 		loader.hide();
      // 	})
      // 	.catch(() => {
      // 		loader.hide();
      // 		let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      // 		this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
      // 	});
    },
    async playCasino() {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", { GameType: GameType.LIVE_CASINO, Lang: localStorage.getItem("locale") })
        .then((url) => {
          window.open(url, "_blank");
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
          this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
        });
    },
    async maintenancepopup() {
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
};
</script>
