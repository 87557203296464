import { ActionTree } from "vuex";
import {
	getLaunchGameUrl,
	getSeamlessLaunchGameUrl,
	getGameList,
	getGameCategories,
} from "@/network/GamesService/Games";
import { GamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GamesMutations } from "./mutations";

export const actions: ActionTree<GamesStateInterface, RootState> = {
	async fetchLaunchGameUrl(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const vendorName = context.rootGetters["vendors/getVendor"].name;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await getSeamlessLaunchGameUrl(
			payload.clientName,
			vendorName,
			memberId,
			payload.Lang,
			payload.gameCode,
			payload.isMobile
		);
		return res;
	},
	async fetchGameList(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getGameList(vendorId, payload.GameCategory);
		context.commit(GamesMutations.SET_GAMES_LIST, res);
	},
	async fetchGameCategory(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getGameCategories(vendorId);
		return res;
	},
};
