import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../pages/home/HomePage.vue";
import InterChangeLogin from "../pages/home/InterchangeLogin.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: HomePage,
	},
	{
		path: "/interchange/:token/:locale",
		name: "home-token",
		component: InterChangeLogin,
		props: true,
	},
	{
		path: "/contact",
		name: "contact",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../pages/contact/ContactPage.vue"),
	},
	{
		path: "/download",
		name: "download",
		component: () => import("../pages/download/DownloadPage.vue"),
	},
	{
		path: "/promotion",
		name: "promotion",
		component: () => import("../pages/promotion/PromotionPage.vue"),
	},
  {
    path: "/vip",
    name: "vip",
    component: () => import("../pages/vip/VipPage.vue"),
  },
	{
		path: "/rank",
		name: "rank",
		component: () => import("../pages/rank/RankPage.vue"),
	},
	{
		path: "/aboutus",
		name: "aboutus",
		component: () => import("../pages/information/AboutUsPage.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("../pages/information/TermsPage.vue"),
	},
	{
		path: "/privacypolicy",
		name: "privacypolicy",
		component: () => import("../pages/information/PrivacyPolicyPage.vue"),
	},
	{
		path: "/responsiblegaming",
		name: "responsiblegaming",
		component: () => import("../pages/information/ResponsibleGamingPage.vue"),
	},
	{
		path: "/statement",
		name: "statement",
		component: () => import("../pages/fund/StatementPage.vue"),
	},
	{
		path: "/referral",
		name: "referral",
		component: () => import("../pages/fund/ReferralPage.vue"),
	},
	{
		path: "/:notFound(.*)",
		name: "notFound",
		component: () => import("../pages/NotFound.vue"),
	},
	{
		path: "/member/forgotpassword",
		name: "forgotpassword",
		component: () => import("../pages/member/ForgotPassword.vue"),
	},
	{
		path: "/games",
		name: "game",
		component: () => import("../pages/games/GamesPage.vue"),
	},
	{
		path: "/surepaydeposit",
		name: "surepaydeposit",
		component: () => import("../pages/SurepayDeposit.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0, behavior: "smooth" };
	},
});

export default router;
