import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/store/index";
import BootstrapVue3 from "bootstrap-vue-3";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { dom } from "@fortawesome/fontawesome-svg-core";
import vueI18n from "@/resource/index";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import toast, { useToast } from "vue-toastification";
import Vue3Marquee from "vue3-marquee";
import VueLoading from "vue-loading-overlay";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import vSelect from "vue-select";
import VueCookies from "vue3-cookies";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "vue-toastification/dist/index.css";
import "vue3-marquee/dist/style.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-select/dist/vue-select.css";
import "./styles/site.css";
import "./styles/modal.css";

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(BootstrapVue3);
app.use(vueI18n);
app.use(toast);
app.use(VueLoading);
app.use(Vue3Marquee);
app.provide("toasted", useToast);
app.use(VueClipboard);
app.use(VueCookies);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("base-image", BaseImage);
app.component("v-select", vSelect);

//SET GLOBAL PROPERTIES
app.config.globalProperties.$isMobile = window.innerWidth < 768 ? true : false;
app.config.globalProperties.toast = useToast();

app.mount("#app");
