<template>
	<HomeBanner></HomeBanner>
	<AnnouncementView></AnnouncementView>
	<HomeSpribeGame></HomeSpribeGame>
	<HomeSwitchSite></HomeSwitchSite>
	<HomeAviatorDice></HomeAviatorDice>
	<HomeGoal></HomeGoal>
	<HomeAbout></HomeAbout>
</template>
<style>
.homeTitle {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 36px;
	color: #fff;
}
</style>
<script>
import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomeSpribeGame from "@/components/content/Home/HomeSpribeGame.vue";
import HomeAbout from "@/components/content/Home/HomeAbout.vue";
import HomeAviatorDice from "@/components/content/Home/HomeAviatorDice.vue";
import HomeGoal from "@/components/content/Home/HomeGoal.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";

export default {
	props: ["token", "time"],
	data() {
		return {};
	},
	components: {
		HomeBanner,
		AnnouncementView,
		HomeSwitchSite,
		HomeSpribeGame,
		HomeAviatorDice,
		HomeGoal,
		HomeAbout,
	},
	methods: {},
};
</script>
