<template>
  <div class="homeAviatorDiceContainer" ref="aviatorDice">
    <base-image :imgSrc="backgroundImg" class="homeAviatorBackground"></base-image>
    <base-image :imgSrc="backgroundMImg" class="homeAviatorMobileBackground"></base-image>
    <div class="homeAviatorDiceContent" :class="{ startAnimate: startAnimation }">
      <b-container class="aviatorDiceContainer-outer">
        <div class="avatorContainer">
          <div class="aviatorDiceItem aviatorGameHeader">
            <base-image :imgSrc="gameAviatorImg" class="aviatorGame"></base-image>
            <div class="playNowBtnContainer" v-if="isLogin" @click="launchGame('aviator')">
              <span>{{ $t("HomeAviatorDice.PlayNow") }}</span>
              <div class="playNowBtn">
                <font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon>
              </div>
            </div>
            <div class="playNowBtnContainer" v-else v-b-modal="'login-modal'">
              <span>{{ $t("HomeAviatorDice.PlayNow") }}</span>
              <div class="playNowBtn">
                <font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon>
              </div>
            </div>
          </div>
          <div class="aviatorDiceItem aviatorGameItem">
            <div class="aviatorImgItem plane">
              <base-image :imgSrc="itemImg.plane" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem coin1">
              <base-image :imgSrc="itemImg.coin1" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem coin2">
              <base-image :imgSrc="itemImg.coin2" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem coin3">
              <base-image :imgSrc="itemImg.coin3" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem coin4">
              <base-image :imgSrc="itemImg.coin4" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem coin5">
              <base-image :imgSrc="itemImg.coin5" class="aviatorImg"></base-image>
            </div>
            <div class="aviatorImgItem cloud">
              <base-image :imgSrc="itemImg.cloud" class="aviatorImg"></base-image>
            </div>
          </div>
        </div>
        <div class="diceContainer">
          <div class="aviatorDiceItem diceGameItem">
            <base-image :imgSrc="itemImg.dice" class="diceImg"></base-image>
          </div>
          <div class="aviatorDiceItem diceGameHeader">
            <base-image :imgSrc="gameDiceImg" class="gameDiceImg"></base-image>
            <div class="playNowBtnContainer" v-if="isLogin" @click="launchGame('dice')">
              <span>{{ $t("HomeAviatorDice.PlayNow") }}</span>
              <div class="playNowBtn">
                <font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon>
              </div>
            </div>
            <div class="playNowBtnContainer" v-else v-b-modal="'login-modal'">
              <span>{{ $t("HomeAviatorDice.PlayNow") }}</span>
              <div class="playNowBtn">
                <font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import { getDefaultCultureCode } from "@/currentCulture";
import { GameType } from "@/common/GameType.ts";

export default {
  data() {
    return {
      backgroundImg: "spribe/assets/images/Home/aviatordice/aviatordice_bg.png",
      backgroundMImg: "spribe/assets/images/Home/aviatordice/aviatordice_mbg.png",
      gameAviatorImg: "spribe/assets/images/Home/aviatordice/game-aviator.png",
      gameDiceImg: "spribe/assets/images/Home/aviatordice/game-dice.png",
      itemImg: {
        cloud: "spribe/assets/images/Home/aviatordice/item-cloud.png",
        plane: "spribe/assets/images/Home/aviatordice/item-plane.png",
        dice: "spribe/assets/images/Home/aviatordice/item-dice.png",
        coin1: "spribe/assets/images/Home/aviatordice/item-coin-1.png",
        coin2: "spribe/assets/images/Home/aviatordice/item-coin-2.png",
        coin3: "spribe/assets/images/Home/aviatordice/item-coin-3.png",
        coin4: "spribe/assets/images/Home/aviatordice/item-coin-4.png",
        coin5: "spribe/assets/images/Home/aviatordice/item-coin-5.png",
      },
      currentScrollPosition: 0,
      topContainerPosition: 0,
      startAnimation: false,
    };
  },
  methods: {
    getThisHeight() {
      this.$nextTick(() => {
        this.topContainerPosition = this.$refs.aviatorDice.offsetTop;
      });
    },
    getCurrentScrollPosition() {
      this.currentScrollPosition = window.scrollY;
    },
    async launchGame(gamecode) {
      const currentLocaleID = getDefaultCultureCode();
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", {
          GameType: GameType.SLOTS,
          Lang: currentLocaleID,
          gameCode: gamecode,
          isMobile: this.$isMobile,
        })
        .then((url) => {
          loader.hide();
          if (url) {
            window.open(url, "blank");
          } else {
            this.toast.error(this.$t("HomeSpribeGame.GameMaintance"), {
              position: this.toastPosition,
            });
          }
        })
        .catch((err) => {
          loader.hide();
          this.toast.error(this.$t("HomeSpribeGame.GameMaintance"), {
            position: this.toastPosition,
          });
        });
    },
  },
  computed: {
    getScrollPosition() {
      return this.currentScrollPosition;
    },
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
  },
  watch: {
    getScrollPosition(scrollTop) {
      if (this.topContainerPosition < scrollTop) {
        this.startAnimation = true;
      }
    },
  },
  created() {
    this.getThisHeight();
  },
  mounted() {
    window.addEventListener("scroll", this.getCurrentScrollPosition);
  },
};
</script>
<style scope>
.homeAviatorDiceContainer {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 6rem 0px;
}
.homeAviatorBackground {
  display: block;
}
.homeAviatorMobileBackground {
  display: none;
}
.homeAviatorDiceContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0px 10rem;
}
.aviatorDiceContainer-outer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  height: 100%;
}
.avatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10vw;
}
.diceContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.aviatorDiceItem {
  width: 50%;
}
.aviatorGameItem {
  position: relative;
}
.aviatorImgItem {
  position: absolute;
}
.aviatorImgItem.plane {
  top: -25vw;
  left: -7%;
  width: 210%;
}

.aviatorImgItem.coin1 {
  top: -11vw;
  left: 23%;
  width: 6vw;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
}
.aviatorImgItem.coin2 {
  top: 1vw;
  left: 89%;
  width: 5vw;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
}
.aviatorImgItem.coin3 {
  top: 5vw;
  left: 136%;
  width: 3vw;
  opacity: 0;
  transform: scale(0);
}
.aviatorImgItem.coin4 {
  top: -18vw;
  left: 125%;
  width: 3vw;
  opacity: 0;
  transform: scale(0);
}
.aviatorImgItem.coin5 {
  top: -11vw;
  left: 100%;
  width: 2vw;
  opacity: 0;
  transform: scale(0);
}
.startAnimate .aviatorImgItem.plane {
  animation: planeAnimation 6s linear 5s infinite, planeFlyInAnimation 5s ease-out;
}
.startAnimate .aviatorImgItem.coin1 {
  animation: coin1Animation 14s linear 5s infinite, coinFlyInAnimation 0.5s ease-out 4.5s;
}
.startAnimate .aviatorImgItem.coin2 {
  animation: coin2Animation 9s linear 5s infinite, coinFlyInAnimation 0.5s ease-out 4.5s;
}
.startAnimate .aviatorImgItem.coin3 {
  animation: coin3Animation 15s linear 5s infinite, coinFlyInAnimation 0.5s ease-out 4.5s;
}
.startAnimate .aviatorImgItem.coin4 {
  animation: coin4Animation 12s linear 5s infinite, coinFlyInAnimation 0.5s ease-out 4.5s;
}
.startAnimate .aviatorImgItem.coin5 {
  animation: coin5Animation 10s linear 5s infinite, coinFlyInAnimation 0.5s ease-out 4.5s;
}
.aviatorImgItem.cloud {
  top: -22vw;
  left: -75%;
  width: 250%;
  animation: cloudAnimation 15s linear infinite;
}
.diceGameHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aviatorGameHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  position: relative;
  z-index: 2;
}
.playNowBtnContainer {
  color: #e4063a;
  font-family: Poppins;
  font-size: 1.7rem;
  line-height: 39px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  margin: 3rem 0px;
  font-weight: bold;
  white-space: nowrap;
}
.playNowBtn {
  width: 2.5rem;
  height: 2.5rem;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  box-shadow: 0 0 0rem 0.3rem #e4063a4d;
  font-size: 1rem;
  color: #000000;
  border: 0.1rem solid #e4063a;
  animation: btnAnimation 1s ease-in infinite;
}
.playNowBtnContainer:hover {
  text-shadow: 0 0 0.6rem #e4063abb;
}
.playNowBtnContainer:hover .playNowBtn {
  background: #e4063a;
  color: #ffffff;
}

@media (max-width: 767px) {
  .homeAviatorDiceContainer {
    padding: unset;
    margin-top: 3rem;
  }
  .homeAviatorDiceContent {
    padding: unset;
  }
  .homeAviatorBackground {
    display: none;
  }
  .homeAviatorMobileBackground {
    display: block;
    height: 140vw !important;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }
  .aviatorDiceContainer-outer {
    justify-content: space-between;
  }
  .avatorContainer {
    flex-wrap: wrap;
    margin-top: 0rem;
  }
  .aviatorDiceItem {
    width: 100%;
  }
  .aviatorGameHeader {
    flex-direction: row;
    padding-top: 0;
    justify-content: space-around;
  }
  .aviatorGameHeader .aviatorGame {
    width: 50% !important;
  }
  .diceContainer {
    padding-bottom: 0rem;
    padding-top: 2rem;
  }
  .playNowBtnContainer {
    white-space: nowrap;
    font-size: 1.2rem;
    padding: 0 0 1rem 0;
  }
  .aviatorImgItem.plane {
    top: -31vw;
    left: -3%;
    width: 134%;
  }
  .aviatorImgItem.cloud {
    top: -18vw;
    left: -5%;
    width: 120%;
    animation: cloudAnimation 15s linear infinite;
  }
  .aviatorImgItem.coin1 {
    top: -10vw;
    left: 19%;
    width: 7ex;
  }
  .aviatorImgItem.coin2 {
    top: 25vw;
    left: 55%;
    width: 7vw;
  }
  .aviatorImgItem.coin3 {
    top: -15vw;
    left: 81%;
    width: 5vw;
  }
  .aviatorImgItem.coin4 {
    top: 2ch;
    left: 90%;
    width: 5vw;
  }
  .aviatorImgItem.coin5 {
    top: -1vw;
    left: 57%;
    width: 5vw;
  }
  .startAnimate .aviatorImgItem.plane {
    animation: planeAnimation 6s linear 5s infinite, planeFlyInAnimation 5s ease-out;
  }
}

@keyframes btnAnimation {
  0% {
    box-shadow: 0 0 0rem 0rem #e4063aff;
  }
  50% {
    box-shadow: 0 0 0.5rem 0.6rem #e4063a4d;
  }
  100% {
    box-shadow: 0 0 1rem 0.9rem #e4063a00;
  }
}

@keyframes cloudAnimation {
  0% {
    transform: translate(3rem);
  }
  50% {
    transform: translate(0);
  }
  100% {
    transform: translate(3rem);
  }
}

@keyframes planeFlyInAnimation {
  0% {
    transform: scale(0) translateX(100%);
  }
  100% {
    transform: scale(1) translateX(0%);
  }
}

@keyframes planeAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes coin1Animation {
  0% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(10deg) translate(-1rem, 1rem) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
}

@keyframes coin2Animation {
  0% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotateY(20deg) translate(-2rem, 0) scale(0.9);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
}

@keyframes coin3Animation {
  0% {
    transform: rotate(-10deg) translate(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(10deg) translate(-2rem, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-10deg) translate(0) scale(1);
    opacity: 1;
  }
}

@keyframes coin4Animation {
  0% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(0deg) translate(-1rem, 1rem) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translate(0) scale(1);
    opacity: 1;
  }
}

@keyframes coin5Animation {
  0% {
    transform: translateX(0) rotateY(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(1rem) rotateY(-60deg) scale(1.5);
    opacity: 1;
  }
  100% {
    transform: translateX(0) rotateY(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes coinFlyInAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
