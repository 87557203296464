import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { ClientStateInterface } from "./state";
import { GetClientInfoResponse } from "@/types/Client/Response/GetClientInfoResponse";

export const getters: GetterTree<ClientStateInterface, RootState> = {
	getClientInfo(state): GetClientInfoResponse[] {
		return state.clientInfo;
	},
};
