<template>
  <b-container fluid class="mobile-bottom-panel d-block d-md-none">
    <div class="d-flex justify-content-around align-items-center bottom-panel-content">
      <!--Home-->
      <div class="mobile-bottom-item" :class="{ active: currentRouterName == 'home' }">
        <router-link class="bottom-panel-item" to="/">
          <font-awesome-icon :icon="['fas', 'house-chimney']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Home") }}</div>
        </router-link>
      </div>
      <!--Deposit-->
      <div class="mobile-bottom-item">
        <div class="bottom-panel-item" @click="maintenancepopup" v-if="isLogin && isMaintenance">
          <font-awesome-icon :icon="['fas', 'wallet']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Deposit") }}</div>
        </div>
        <div class="bottom-panel-item" v-else-if="isLogin && !checkIsLoadMemberBank">
          <b-skeleton type="avatar" class="skeleton-icon"></b-skeleton>
          <b-skeleton class="w-100"></b-skeleton>
        </div>
        <div class="bottom-panel-item" data-bs-toggle="modal" :data-bs-target="this.depositModal" v-else>
          <font-awesome-icon :icon="['fas', 'wallet']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Deposit") }}</div>
        </div>
      </div>
      <!--Withdrawal-->
      <div class="mobile-bottom-item">
        <div class="bottom-panel-item" @click="maintenancepopup" v-if="isLogin && isMaintenance">
          <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Withdrawal") }}</div>
        </div>
        <div class="bottom-panel-item" v-else-if="isLogin && !checkIsLoadMemberBank">
          <b-skeleton type="avatar" class="skeleton-icon"></b-skeleton>
          <b-skeleton class="w-100"></b-skeleton>
        </div>
        <div class="bottom-panel-item" data-bs-toggle="modal" :data-bs-target="this.withdrawalModal" v-else>
          <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Withdrawal") }}</div>
        </div>
      </div>
      <!--Statement-->
      <div class="mobile-bottom-item" :class="{ active: currentRouterName == 'statement' }">
        <router-link class="bottom-panel-item" to="/statement" v-if="isLogin && checkIsMemberBankExist === true">
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </router-link>
        <div
          class="bottom-panel-item"
          v-else-if="isLogin && !checkIsMemberBankExist === false"
          v-b-modal="'bankInfo-modal'"
        >
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </div>
        <div class="bottom-panel-item" v-else v-b-modal="'login-modal'">
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </div>
      </div>
      <!--Switch-->
      <div class="mobile-bottom-item">
        <div class="bottom-panel-item" v-b-modal="'interchange-modal'">
          <font-awesome-icon :icon="['fas', 'shuffle']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Switch") }}</div>
        </div>
      </div>
      <div class="indicator"></div>
    </div>
  </b-container>
</template>
<style scoped>
.mobile-bottom-panel {
  display: block;
  right: 0;
  bottom: 0;
  left: 0;
  height: 70px;
  position: fixed;
  z-index: 999;
  padding: 10px 0px;
  background: #000000;
  border-top: 8px solid #ffffff;
}
.bottom-panel-content {
  position: relative;
}
.mobile-bottom-item {
  position: relative;

  width: 20%;
  display: flex;
  justify-content: center;
}
.bottom-panel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  min-width: 20%;
  position: relative;
}
.bottom-panel-icon {
  font-size: 1.5em;
  position: relative;
  z-index: 10;
}
.bottom-panel-text {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  font-weight: bold;
}
.skeleton-icon {
  width: 1.5em;
  height: 1.5em;
}

.indicator {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  background: #000000;
  top: -100%;
  border-radius: 50%;
  border: 0.4rem solid #ffffff;
  transition: 0.5s;
  left: -20%;
}

.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1.45rem;
  width: 1.3rem;
  height: 1.2rem;
  background: #000;
  border-top-right-radius: 1rem;
  box-shadow: 0px -0.5rem 0 0 #fff;
  display: none;
}

.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1.46rem;
  width: 1.3rem;
  height: 1.2rem;
  background: #000;
  border-top-left-radius: 1rem;
  box-shadow: 0px -0.5rem 0 0 #fff;
  display: none;
}

.bottom-panel-content .mobile-bottom-item:hover .bottom-panel-icon {
  color: #ff0;
}

.bottom-panel-content .mobile-bottom-item:hover .bottom-panel-text {
  color: #ff0;
}

.bottom-panel-content .mobile-bottom-item.active .bottom-panel-icon {
  transition: 0.5s;
  transform: translateY(-1.5rem);
}
.bottom-panel-content .mobile-bottom-item:nth-child(1).active ~ .indicator {
  left: calc(4.5rem / 2 + 0%);
}
.bottom-panel-content .mobile-bottom-item:nth-child(2).active ~ .indicator {
  left: calc(4.5rem / 2 + 20%);
}
.bottom-panel-content .mobile-bottom-item:nth-child(3).active ~ .indicator {
  left: calc(4.5rem / 2 + 40%);
}
.bottom-panel-content .mobile-bottom-item:nth-child(4).active ~ .indicator {
  left: calc(4.5rem / 2 + 60%);
}
.bottom-panel-content .mobile-bottom-item:nth-child(5).active ~ .indicator {
  left: calc(4.5rem / 2 + 80%);
}

@media (max-width: 650px) {
  .bottom-panel-content .mobile-bottom-item:nth-child(1).active ~ .indicator {
    left: calc(3rem / 2 + 0%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(2).active ~ .indicator {
    left: calc(3rem / 2 + 20%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(3).active ~ .indicator {
    left: calc(3rem / 2 + 40%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(4).active ~ .indicator {
    left: calc(3rem / 2 + 60%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(5).active ~ .indicator {
    left: calc(3rem / 2 + 80%);
  }
}
@media (max-width: 500px) {
  .bottom-panel-content .mobile-bottom-item:nth-child(1).active ~ .indicator {
    left: calc(2rem / 2 + 0%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(2).active ~ .indicator {
    left: calc(2rem / 2 + 20%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(3).active ~ .indicator {
    left: calc(2rem / 2 + 40%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(4).active ~ .indicator {
    left: calc(2rem / 2 + 60%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(5).active ~ .indicator {
    left: calc(3rem / 2 + 80%);
  }
}

@media (max-width: 450px) {
  .bottom-panel-content .mobile-bottom-item:nth-child(1).active ~ .indicator {
    left: calc(1rem / 2 + 0%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(2).active ~ .indicator {
    left: calc(1rem / 2 + 20%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(3).active ~ .indicator {
    left: calc(1rem / 2 + 40%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(4).active ~ .indicator {
    left: calc(1rem / 2 + 60%);
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(5).active ~ .indicator {
    left: calc(1rem / 2 + 80%);
  }
}

@media (max-width: 400px) {
  .bottom-panel-content .mobile-bottom-item:nth-child(1).active ~ .indicator {
    left: 0%;
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(2).active ~ .indicator {
    left: 20%;
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(3).active ~ .indicator {
    left: 40%;
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(4).active ~ .indicator {
    left: 60%;
  }
  .bottom-panel-content .mobile-bottom-item:nth-child(5).active ~ .indicator {
    left: 80%;
  }
}
</style>
<script>
export default {
  data() {
    return {
      depositModal: "#deposit-modal",
      withdrawalModal: "#withdrawal-modal",
      bankModal: "#bankInfo-modal",
      loginModal: "#login-modal",
      defaultDepositModal: "#deposit-modal",
      defaultWithdrawalModal: "#withdrawal-modal",
    };
  },
  computed: {
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
    currentRouterName() {
      return this.$route.name;
    },
  },
  methods: {
    setModalPopUp() {
      if (this.isLogin && this.checkIsMemberBankExist === true) {
        this.depositModal = this.defaultDepositModal;
        this.withdrawalModal = this.defaultWithdrawalModal;
      } else if (this.isLogin && this.checkIsMemberBankExist === false) {
        this.depositModal = this.bankModal;
        this.withdrawalModal = this.bankModal;
      } else {
        this.depositModal = this.loginModal;
        this.withdrawalModal = this.loginModal;
      }
    },
    async maintenancepopup() {
      let toastPosition = this.$isMobile ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
  watch: {
    isLogin() {
      this.setModalPopUp();
    },
    checkIsMemberBankExist() {
      this.setModalPopUp();
    },
  },
  created() {
    this.setModalPopUp();
  },
};
</script>
