<template>
	<b-container class="homeAbountContainer">
		<div class="aboutTitle">{{ $t("HomeAbout.AboutSpribe") }}</div>
		<div class="aboutDesc">
			<i18n-t tag="div" keypath="HomeAbout.AboutDesc" class="textDesc">
				<template #newline><br /></template>
			</i18n-t>
		</div>
	</b-container>
</template>
<style scoped>
.homeAbountContainer {
	padding: 3rem 2rem;
}
.aboutTitle {
	color: #000000;
	font-weight: bold;
	font-size: 1.2rem;
	text-align: left;
	padding-bottom: 1.5rem;
}
.aboutDesc {
	color: #989898;
	font-size: 0.8rem;
	text-align: justify;
}

@media (max-width: 767px) {
	.homeAbountContainer {
		padding: 3rem 2rem;
	}
}
</style>
<script>
export default {
	data() {
		return {};
	},
};
</script>
