export default {
	ABOUTUS: "TENTANG KITA",
	Description1:
		"Spribe dilancarkan di Malaysia pada bulan Oktober 2019. Permainan ini disukai oleh setiap pemain kasino, jadi ia memenangi permainan kasino kedua terbesar di Malaysia. Senarai permainan: GreatBlue, Safari heat, DolphinReef, Iceland, Thai Boxing, Blackjack, Roulette, Baccarat dan permainan lain ada di dalamnya.",
	Description2:
		"Semua orang di Malaysia menyukai permainan ini kerana boleh dimainkan bila-bila masa, di mana sahaja dengan telefon bimbit moden, sesuai untuk orang yang berumur lebih dari 18 tahun, Gunakan masa lapang anda untuk memenangkan lebih banyak wang dengan permainan perjudian ini! Sama seperti jenis pertaruhan yang anda menang dan menang di SkyCasino di Genting Highlands, Adakah anda masih ragu-ragu? Mula bermain sekarang!",
	Description3:
		"Anda boleh bermain di komputer tanpa memuat turun emulator android. Aplikasi ini mempunyai versi komputer dan Android atau IOS Iphone, ini memberikan anda keselamatan dan perlindungan terbaik, anda tidak perlu bimbang tentang kebocoran data anda, lebih daripada keselamatan platform apa pun, Muat turun apk klien permainan di sini.",
	Description4:
		"Fungsi permainan ini mempunyai bonus misi harian untuk anda selesaikan dan dapatkan lebih banyak ganjaran setiap hari. Permainan bingo sosial harian adalah Spribe melancarkan ciri baru! Setiap hari, ada permainan bingo percuma untuk semua pemain hari ini! Ganjaran minimum ialah 60 dan hingga 2500 ringgit atau lebih.",
	Description5:
		"Lucky Wheel juga merupakan ciri loteri terbaru yang dilancarkan oleh Spribe! Pemain hari ini boleh mendapatkan bintang percuma, yang boleh ditukar dengan Lucky Wheel atau Mystery Box! Boleh mengumpulkan bintang anda dan menggunakannya dengan segera, Ia mesti digunakan pada hari tersebut, jika tidak, ia akan disegarkan selepas 24 jam, Ingatlah, Jika tidak, anda akan menyesal.",
	Description6:
		"Spribe mempunyai kasino langsung dan mesin slot elektronik, baik dalam satu aplikasi permainan, muat turun dan mula bermain sekarang!",
};
